.ant-modal-content {
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
}

.ant-pagination-item-active {
  border: 1px solid #0f1a4f;
  background: #0f1a4f;
  color: #fff;
}

.ant-pagination-item {
  border-radius: 50%;
}

.ant-pagination-item-link {
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-modal-header {
  padding: 16px 24px;
  border-bottom: none;
  background: #30355b;
  border-radius: 16px 16px 0px 0px;
}

.ant-modal-title {
  margin: 0;
  color: #ffb619;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  text-align: center;
}

/* .ant-modal-content .anticon svg {
  display: inline-block;
  color: #fff;
} */

.dropdown {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown .optionValue .email {
  color: #30345e;
  font-size: 14px;
  font-weight: 400;
}

.dropdown .optionValue .name {
  color: #30345e;
  font-size: 14px;
  font-weight: 600;
}
